import React from "react";

import Hero from "../components/Hero";
import Layout from "../components/Layout";

const ErrorPage: React.VFC = () => {
  return (
    <Layout>
      <Hero page="homepage" header="Sorry" subHeader="We couldn't find what you're looking for." />
    </Layout>
  );
};

export default ErrorPage;
